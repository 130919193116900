/* .main {
  height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 1024px) {
    .w-lg {
        max-width: 48rem
    }
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}